import {store} from "../../../../../store/index";
import Api from "../../../../../store/Services/Api";

class AssetReportService{
    getAccountWiseReport(param){
        const url = `api/assets/report/account-wise/filter`
        const params ={
            'page-index':param.pageIndex,
            'branch':param.branch,
            'today': param.today,
            'month':param.month,
            'account':param.account,
            'type':param.type,
            'year':param.year,
            'from':param.from,
            'session_year':param.session_years, 
            'to':param.to,
            'searched':param.searched,
            'offset':param.offset
        }
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers,params);
    }
    getAccountWiseExcel(param){
        const url = `api/assets/report/account-wise/excel`
        const params ={
            'page-index':param.pageIndex,
            'branch':param.branch,
            'today': param.today,
            'month':param.month,
            'account':param.account,
            'type':param.type,
            'year':param.year,
            'from':param.from,
            'session_year':param.session_years, 
            'to':param.to,
            'searched':param.searched,
            'offset':param.offset
        }
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.getFile(url,headers,params);
    }
    getBranchWiseReport(param){
        const url = `api/assets/report/branch-wise/filter`
        const params ={
                'page-index':param.pageIndex,
                'account':param.account,
                'branch':param.branch,
                'type':param.type,
                'today': param.today,
                'month':param.month,
                'year':param.year,
                'from':param.from,
                'session_year':param.session_years, 
                'to':param.to,
                'searched':param.searched,
                'offset':param.offset
        }
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers,params);
    }
    getBranchWiseExcel(param){
        const url = `api/assets/report/branch-wise/excel`
        const params ={
                'page-index':param.pageIndex,
                'account':param.account,
                'branch':param.branch,
                'type':param.type,
                'today': param.today,
                'month':param.month,
                'year':param.year,
                'from':param.from,
                'session_year':param.session_years, 
                'to':param.to,
                'searched':param.searched,
                'offset':param.offset
        }
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.getFile(url,headers,params);
    }
    getDayWiseReport(param){
        const url = `api/assets/report/day-wise/filter`
        const params ={
                'page-index':param.pageIndex,
                'account':param.account,
                'branch':param.branch,
                'type':param.type,
                'today': param.today,
                'month':param.month,
                'year':param.year,
                'from':param.from,
                'session_year':param.session_years, 
                'to':param.to,
                'searched':param.searched,
                'offset':param.offset
        }
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers,params);
    }
    getDayWiseExcel(param){
        const url = `api/assets/report/day-wise/excel`
        const params ={
                'page-index':param.pageIndex,
                'account':param.account,
                'branch':param.branch,
                'type':param.type,
                'today': param.today,
                'month':param.month,
                'year':param.year,
                'from':param.from,
                'session_year':param.session_years, 
                'to':param.to,
                'searched':param.searched,
                'offset':param.offset
        }
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.getFile(url,headers,params);
    }
    getMonthWiseReport(param){
        console.log(param);
        const url = `api/assets/report/month-wise/filter`
        const params ={
                'page-index':param.pageIndex,
                'account':param.account,
                'branch':param.branch,
                'type':param.type,
                'today': param.today,
                'month':param.month,
                'year':param.year,
                'from':param.from,
                'session_year':param.session_years, 
                'to':param.to,
                'searched':param.searched,
                'offset':param.offset
        }
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers,params);
    }
    getMonthWiseExcel(param){
        const url = `api/assets/report/month-wise/excel`
        const params ={
                'page-index':param.pageIndex,
                'account':param.account,
                'branch':param.branch,
                'type':param.type,
                'today': param.today,
                'month':param.month,
                'year':param.year,
                'from':param.from,
                'session_year':param.session_years, 
                'to':param.to,
                'searched':param.searched,
                'offset':param.offset
        }
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.getFile(url,headers,params);
    }
}

export default new AssetReportService();